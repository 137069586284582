import React, {useEffect, useRef} from "react"
import {makeStyles} from "@mui/styles";
import cx from "classnames";
import {gsap} from "gsap";
import {StaticImage} from "gatsby-plugin-image"

const useStyles = makeStyles(() => ({
    curve: {
        position: 'absolute',
        objectFit: 'contain',
        opacity: 1,
        transition: 'opacity 500ms linear',
    },
    showAnimation: {
        transition: 'opacity 500ms linear, right 800ms linear, bottom 800ms linear, left 800ms linear, top 800ms linear, height 800ms linear',
    },
    firstBackgroundCurve: {
        top: '10vh',
        right: '68vw',
        height: '28%',
        width: '28%',
    },
    secondBackgroundCurve: {
        bottom: '10vh',
        left: '10vw',
        height: '25%',
        width: '25%',
    },
    thirdBackgroundCurve: {
        bottom: '4vh',
        right: '6vw',
        height: '40%',
        width: '40%',
    },
    firstZoomedBackgroundCurve: {
        top: '0vh',
        right: '5vw',
        height: '50%',
        width: '50%',
    },
    secondZoomedBackgroundCurve: {
        bottom: '-5vh',
        left: '50vw',
        height: '25%',
        width: '25%',
    },
    thirdZoomedBackgroundCurve: {
        bottom: '-5vh',
        right: '-2vw',
        height: '40%',
        width: '40%',
    },
    hideCurves: {
        opacity: 0,
        transition: 'opacity 500ms linear',
    }
}));

const AnimatedCurves = ({zoomedStyle, hideCurves, hideAnimation}) => {
    const classes = useStyles();
    const firstCurveRef = useRef(null)
    const secondCurveRef = useRef(null)
    const thirdCurveRef = useRef(null)

    const runCurveAnimation = (curve, rotation, duration) => {
        const loopAnim = gsap.to(curve.current, {
            rotation: rotation,
            ease: "none",
            duration: duration,
            onComplete: () => {
                loopAnim.play(0);
            },
            paused: true
        });
        loopAnim.play();
    }

    useEffect(() => {
        if (firstCurveRef) {
            runCurveAnimation(firstCurveRef, "+=360", 15);
        }
    }, [firstCurveRef]);

    useEffect(() => {
        if (secondCurveRef) {
            runCurveAnimation(secondCurveRef, "-=360", 18);
        }
    }, [secondCurveRef]);

    useEffect(() => {
        if (thirdCurveRef) {
            runCurveAnimation(thirdCurveRef, "+=360", 12);
        }
    }, [thirdCurveRef]);

    return <>
        <div ref={firstCurveRef} className={cx(classes.curve, {
            [classes.hideCurves]: hideCurves,
            [classes.firstBackgroundCurve]: !zoomedStyle,
            [classes.firstZoomedBackgroundCurve]: zoomedStyle,
            [classes.showAnimation]: !hideAnimation,
        })}>
            <StaticImage src={"../assets/shapes/First_curve.webp"} alt="curve"/>
        </div>
        <div ref={secondCurveRef} className={cx(classes.curve, {
            [classes.hideCurves]: hideCurves,
            [classes.secondBackgroundCurve]: !zoomedStyle,
            [classes.secondZoomedBackgroundCurve]: zoomedStyle,
            [classes.showAnimation]: !hideAnimation,
        })}>
            <StaticImage src={"../assets/shapes/Second_curve.webp"} alt="curve"/>
        </div>
        <div ref={thirdCurveRef} className={cx(classes.curve, {
            [classes.hideCurves]: hideCurves,
            [classes.thirdBackgroundCurve]: !zoomedStyle,
            [classes.thirdZoomedBackgroundCurve]: zoomedStyle,
            [classes.showAnimation]: !hideAnimation,
        })}>
            <StaticImage src={"../assets/shapes/Third_curve.webp"} alt="curve"/>
        </div>
    </>
}

export default AnimatedCurves