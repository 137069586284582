import React from "react"
import {makeStyles} from "@mui/styles";
import cx from "classnames";
import {alpha, useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {StaticImage} from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'relative',
        overflow: 'hidden',
        padding: '10px 32px',
        backgroundColor: theme.palette.orange.main,
        color: 'white',
        textTransform: "uppercase",
        fontFamily: 'Oswald',
        fontSize: 32,
        fontWeight: 700,
        width: 'fit-content',
        lineHeight: 1.5,
        transition: 'background-color 300ms linear, color 300ms linear',

        '&:hover': {
            backgroundColor: theme.palette.grey.second,
            color: 'black',
            transition: 'background-color 300ms linear, color 300ms linear',
        },
    },
    buttonSmartphone: {
        fontSize: 20,
    },
    buttonFocused: {
        color: theme.palette.orange.main,
        transition: 'color 100ms linear',

        '&:hover': {
            color: theme.palette.orange.main,
            transition: 'color 100ms linear',
        },
    },
    buttonDisabled: {
        backgroundColor: alpha(theme.palette.orange.main, .5),
        transition: 'background-color 300ms linear',

        '&:hover': {
            backgroundColor: alpha(theme.palette.orange.main, .5),
            color: 'white',
            transition: 'background-color 300ms linear',
        },
    },
    elementTopLeft: {
        top: -40,
        left: -40,
    },
    showElementTopLeft: {
        top: 0,
        left: 0,
        transition: 'left 300ms linear, top 300ms linear',
    },
    elementTopRight: {
        top: -40,
        right: -40,
    },
    showElementTopRight: {
        top: 0,
        right: 0,
        transition: 'right 300ms linear, top 300ms linear',
    },
    elementBottomLeft: {
        bottom: -40,
        left: -40,
    },
    showElementBottomLeft: {
        bottom: -20,
        left: 0,
        transition: 'left 300ms linear, bottom 300ms linear',
    },
    elementBottomRight: {
        bottom: -40,
        right: -40,
    },
    showElementBottomRight: {
        bottom: -20,
        right: 0,
        transition: 'bottom 300ms linear, right 300ms linear',
    },

    element: {
        zIndex: 200,
        position: 'absolute',
        transition: 'left 300ms linear, right 300ms linear, bottom 300ms linear, top 300ms linear',
    },
}));

const Button = ({text, onClick, disabled, ...props}) => {
    const classes = useStyles();
    const theme = useTheme();
    const lessThanTablet = useMediaQuery(theme.breakpoints.down('tablet'));
    const [hovered, setHovered] = React.useState(null)
    const [focused, setFocused] = React.useState(null)

    return (
        <div className={cx(classes.button, {
            [classes.buttonFocused]: focused && !disabled,
            [classes.buttonDisabled]: disabled,
            [classes.buttonSmartphone]: lessThanTablet,
        })}
             onMouseOver={() => setHovered(true)}
             onMouseLeave={() => setHovered(false)}
             onClick={onClick}
             onMouseDown={() => setFocused(true)}
             onMouseUp={() => setFocused(false)}
             {...props}
        >
            {text}
            <div className={cx(classes.element, classes.elementTopLeft, {
                [classes.showElementTopLeft]: hovered && !focused && !disabled,
            })}>
                <StaticImage src={"../assets/shapes/button-shapes/shape-1.svg"}
                             alt="Arrow"

                />
            </div>
            <div className={cx(classes.element, classes.elementTopRight, {
                [classes.showElementTopRight]: hovered && !focused && !disabled,
            })}>
                <StaticImage src={"../assets/shapes/button-shapes/shape-2.svg"}
                             alt="Arrow"

                />
            </div>
            <div className={cx(classes.element, classes.elementBottomLeft, {
                [classes.showElementBottomLeft]: hovered && !focused && !disabled,
            })}>
                <StaticImage src={"../assets/shapes/button-shapes/shape-3.svg"}
                             alt="Arrow"

                />
            </div>
            <div className={cx(classes.element, classes.elementBottomRight, {
                [classes.showElementBottomRight]: hovered && !focused && !disabled,
            })}>
                <StaticImage src={"../assets/shapes/button-shapes/shape-4.svg"}
                             alt="Arrow"

                />
            </div>
        </div>
    )
}

export default Button