import React from "react"
import {graphql, useStaticQuery} from "gatsby";

const Seo = ({children, title, description}) => {
    const {strapiMainSeo: seo} = useStaticQuery(graphql`
      query {
          strapiMainSeo {
            Site_url
            Description
            Image {
              url
            }
            Title
            Twitter_user_name
          }
      }
    `)

    return (
        <>
            <title>{title || seo.Title}</title>
            <meta name="description" content={description || seo.Description} />
            <meta name="image" content={seo.Image.url} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title || seo.Title} />
            <meta name="twitter:url" content={seo.Site_url} />
            <meta name="twitter:description" content={description || seo.Description} />
            <meta name="twitter:image" content={seo.Image.url} />
            <meta name="twitter:creator" content={seo.Twitter_user_name} />
            <link rel="icon" href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>" />
            {children}
        </>

    )
}

export default Seo