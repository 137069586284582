import React, {useEffect, useState} from "react"
import Button from "../components/button";
import {navigate} from "gatsby";
import {makeStyles} from "@mui/styles";
import cx from "classnames";
import AnimatedCurves from "../components/animated-curves";
import Seo from "../components/seo";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'calc(100vw - 100px)',
        padding: 60,
        gap: 32,
        opacity: 1,
        transition: 'opacity 500ms linear',

        [theme.breakpoints.down('laptop')]: {
            width: '100vw',
        },
        [theme.breakpoints.down('tablet')]: {
            padding: 32,
        },
    },
    initBlur: {
        opacity: 0,
        transition: 'opacity 500ms linear',
    },
    title: {
        color: theme.palette.grey.second,
        fontFamily: 'Oswald',
        textTransform: "uppercase",
        lineHeight: 1,
        fontWeight: 700,
        fontSize: 56,
        textAlign: 'center',
    },
    description: {
        color: theme.palette.grey.second,
        fontFamily: 'Montserrat',
        fontSize: 16,
        minWidth: 360,
        maxWidth: 1040,
        textAlign: 'center',
        [theme.breakpoints.down('tablet')]: {
            fontSize: 12,
            minWidth: 160,
        },
    },
}));

const NotFoundPage = () => {
    const classes = useStyles();
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return <div className={cx(classes.contentContainer, {
        [classes.initBlur]: !isMounted
    })}>
        <Seo />
        <AnimatedCurves/>
        <div className={classes.title}>{'Oops, something went wrong!'}</div>
        <div
            className={classes.description}>{"It looks like the page you're looking for can't be found. It may have been removed or moved elsewhere. Please return to the homepage or use the search bar to continue browsing."}</div>
        <Button
            text={'Return to Homepage'}
            onClick={() => navigate("/")}
        />
    </div>
}

export default NotFoundPage
